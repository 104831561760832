const Ext = require('ext')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')

// Read-only version of MultiSelectComboBox
const ReadOnlyMultiSelectComboBox = Ext.define(null, {
  extend: ReadOnlyTextField,

  constructor(cfg = {}) {
    // Use the readOnlyName if provided, else default to the clone's name
    if (cfg.clone.readOnlyName) {
      cfg.name = cfg.clone.readOnlyName
    } else {
      cfg.name = cfg.clone.hiddenName || cfg.clone.name
      this.useStoreDisplayValue = true
    }

    if (cfg.clone) {
      // Copy the value from the clone
      cfg.value = cfg.clone.getValue()
    }

    // Call the parent constructor
    this.callParent([cfg])

    // Store a reference to the original component
    this.clone = cfg.clone
  },

  // Override setValue to display the selected items
  setValue(value) {
    if (this.useStoreDisplayValue) {
      // Hide if the original component is hidden
      if (this.clone.hidden) {
        this.setVisible(false)
      }

      // Check if the store has data
      if (
        this.clone.store &&
        (this.clone.store.loading || !this.clone.store.getCount())
      ) {
        console.log('Store is not ready, waiting for load...')
        // Store is either loading or empty, wait for it to load
        this.clone.store.on(
          'load',
          function () {
            this.setValue(value)
          },
          this,
          { single: true }
        )
        // Show nothing until the store is loaded
        this.callParent([''])
        return
      }

      let displayValues = []
      if (this.clone.valueField && this.clone.store) {
        // Split the value into an array (assuming comma-separated IDs)
        let values = value ? value.split(',') : []
        values.forEach(function (val) {
          val = val.trim()
          // Find the index of the record in the store
          const index = this.clone.store.findExact(this.clone.valueField, val)
          if (index !== -1) {
            const record = this.clone.store.getAt(index)
            displayValues.push(record.get(this.clone.displayField))
          } else if (Ext.isDefined(this.clone.valueNotFoundText)) {
            displayValues.push(this.clone.valueNotFoundText)
          } else {
            console.warn('Value not found in store:', val)
          }
        }, this)
      }

      // Join the display values to a string
      value = displayValues.join(', ')
    }

    // Call the parent setValue with the display value
    this.callParent([value])
  }
})

module.exports = ReadOnlyMultiSelectComboBox
