const Ext = require('ext')

// Define the custom multi-select combo box component
const MultiSelectComboBox = Ext.extend(Ext.form.TriggerField, {
  xtype: 'smx-multi', // Custom xtype for the component

  // Configuration options
  triggerClass: 'x-form-arrow-trigger',
  editable: false, // Prevent user from typing into the field

  // Store and selectedValues
  store: null, // The store containing the options
  displayField: 'name', // Field to display (e.g., 'name')
  valueField: 'id', // Field to store the value (e.g., 'id')
  selectedValues: [], // Array to hold selected values

  // Initialize the component
  initComponent: function () {
    // Call the parent class's initComponent method
    MultiSelectComboBox.superclass.initComponent.call(this)

    // Ensure the store is initialized
    if (!this.store) {
      this.store = new Ext.data.ArrayStore({
        fields: [this.valueField, this.displayField],
        data: []
      })
    }

    // Create the dropdown panel
    this.createDropdownPanel()
  },

  // Override the afterRender method
  afterRender: function () {
    MultiSelectComboBox.superclass.afterRender.call(this)
    // Ensure the display is updated after render
    this.updateValue()
  },

  // Override the onTriggerClick method to toggle the dropdown
  onTriggerClick: function () {
    if (this.disabled) {
      return
    }
    this.toggleDropdown()
  },

  // Create the dropdown panel containing the options
  createDropdownPanel: function () {
    this.dropdown = new Ext.Panel({
      floating: true,
      hidden: true,
      width: 220,
      height: 80, // Fixed height for the dropdown
      autoScroll: true,
      style: {
        position: 'absolute',
        zIndex: 20000 // Ensure it appears above other elements
      },
      items: [],
      // Add a class for styling if needed
      cls: 'multi-select-dropdown'
    })

    // Populate the dropdown with options
    if (this.store) {
      this.store.each(function (record) {
        const checkbox = new Ext.form.Checkbox({
          boxLabel: record.get(this.displayField),
          inputValue: record.get(this.valueField),
          checked: this.selectedValues.indexOf(record.get(this.valueField)) !== -1,
          listeners: {
            check: function (checkbox, checked) {
              this.onOptionCheck(record, checkbox, checked)
            },
            scope: this
          }
        })

        this.dropdown.add(checkbox)
      }, this)
    }

    // Render the dropdown to the body element
    this.dropdown.render(Ext.getBody())
  },

  // Toggle the visibility of the dropdown
  toggleDropdown: function () {
    if (this.dropdown.isVisible()) {
      this.dropdown.hide()
      Ext.getBody().un('click', this.onBodyClick, this)
    } else {
      // Position the dropdown under the field
      const position = this.getPosition()
      const x = position[0]
      const y = position[1] + this.getHeight()

      this.dropdown.setPosition(x, y)
      this.dropdown.show()
      Ext.getBody().on('click', this.onBodyClick, this, { buffer: 10 })
    }
  },

  // Close the dropdown when clicking outside
  onBodyClick: function (e) {
    if (!e.within(this.wrap) && !e.within(this.dropdown.getEl())) {
      this.dropdown.hide()
      Ext.getBody().un('click', this.onBodyClick, this)
    }
  },

  // Handle option check/uncheck
  onOptionCheck: function (record, checkbox, checked) {
    const value = record.get(this.valueField)

    // Ensure value is not an empty string
    if (value === '') {
      return
    }

    if (checked) {
      if (this.selectedValues.indexOf(value) === -1) {
        this.selectedValues.push(value)
      }
    } else {
      const index = this.selectedValues.indexOf(value)
      if (index !== -1) {
        this.selectedValues.splice(index, 1)
      }
    }

    // Update the display
    this.updateValue()
  },

  // Update the field's value and display text
  updateValue: function () {
    if (!this.rendered) {
      // Component not rendered yet, defer update
      return
    }

    const selectedRecords = this.store.queryBy(function (record) {
      return this.selectedValues.indexOf(record.get(this.valueField)) !== -1
    }, this)

    // Collect the display fields from the selected records
    const displayTexts = []
    selectedRecords.each(function (record) {
      displayTexts.push(record.get(this.displayField))
    }, this)

    const displayText = displayTexts.join(', ')

    this.setRawValue(displayText || 'Select tags')
  },

  // Override the getValue method to return selected values
  getValue: function () {
    // Filter out any empty strings from selectedValues
    const values = this.selectedValues.filter(function (val) {
      return val !== ''
    })
    // Return the selected values as a comma-separated string
    return values.join(',')
  },

  // Optionally, override getName if needed
  getName: function () {
    return this.name || this.hiddenName
  },

  // Override the setValue method
  setValue: function (value) {
    if (value != null && value !== '') {
      var selectedValues = value.split(',').filter(function (val) {
        return val !== ''
      })
      this.selectedValues = selectedValues
    } else {
      this.selectedValues = []
    }
    // Update the display
    this.updateValue()
  },

  // Override the onDestroy method to clean up
  onDestroy: function () {
    if (this.dropdown) {
      this.dropdown.destroy()
    }
    Ext.getBody().un('click', this.onBodyClick, this)
    MultiSelectComboBox.superclass.onDestroy.call(this)
  }
})

// Register the component with an xtype
Ext.reg('smx-multi', MultiSelectComboBox)

// Export the component so it can be used elsewhere
module.exports = MultiSelectComboBox
