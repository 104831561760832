const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const ReportsListPanel = require('reporting/ReportsListPanel')
const ReportGridPanel = require('reporting/ReportGridPanel')
const ReportRecord = require('reporting/ReportRecord')
const DateField = require('admin/component/form/DateField')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ComboBox = require('admin/component/form/ComboBox')
const VendorResource = require('admin/business/VendorResource')
const WindowMgr = require('admin/desktop/WindowMgr').default
const encodeForm = require('url/encodeForm').default
const File = require('api/file')
const _ = require('lodash')
const MultiSelectComboBox = require('admin/component/form/MultiSelectComboBox')
const TRANSLATIONS = {
  PDF: t('PDF'),
  HTML: t('HTML'),
  CSV: t('CSV')
}

const Report = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.Report',
  translations: {
    title: t('Report'),
    plural: t('Reports'),
    open: t('Run Report'),
    list: t('List Reports')
  },
  name: t('Report'),
  code: 'REPORT',
  resourceName: 'reports',
  explorable: true,

  operations: {
    create: false,
    save: false,
    open: true,
    remove: false,
    list: true
  },

  layout: {
    newResource: {
      width: 390,
      height: 'auto'
    },
    openResource: {
      width: 725,
      height: 525,
      minHeight: 525
    }
  },

  listPanel: ReportsListPanel,
  detailPanel: ReportGridPanel,
  record: ReportRecord,

  getTitle(record) {
    const displayName = record.store.parentRecord.get('displayName')
    return String.format(
      '{0}{1}',
      displayName ? displayName + ': ' : '',
      record.getName()
    )
  },

  // Override to show options menu first
  open(record, options = {}) {
    if (record.hasReportingOptions() && !options.reportOptions) {
      return this.showReportOptions(record)
    }
    this.callParent(arguments)
  },

  getBaseTimestamp() {
    let baseTimestamp = new Date()
    if (baseTimestamp.getMinutes() > 0) {
      baseTimestamp = baseTimestamp.add(Date.HOUR, 1)
      baseTimestamp.setMinutes(0)
    }
    baseTimestamp.setSeconds(0)
    baseTimestamp.setMilliseconds(0)
    return baseTimestamp.getTime()
  },

  getReportOptions(form, report, reportOptions) {
    const options = report.get('options') || []
    reportOptions = reportOptions || []

    const params = options.reduce(function (memo, option) {
      const { provided, required, name } = option
      const value = form[name] || reportOptions[name] || option.defaultValue
      if (!provided || name === 'basetimestamp') {
        if (value || required) {
          memo[name] = value
        }
      } else if (name === 'locale') {
        memo[name] = value || LOCALE || 'en'
      }
      return memo
    }, {})

    // For mail events remove the type option if set to ALL
    if (report.id === 'mailevents' && params.type === 'ALL') {
      delete params.type
    }

    if (params.basetimestamp == null) {
      params.basetimestamp = this.getBaseTimestamp()
    }

    return encodeForm(params)
  },

  async openReportExport(form, report, reportOptions) {
    const params = this.getReportOptions(form, report, reportOptions)
    const format = (form.format && form.format.toLowerCase()) || 'csv'

    return File.download(report.getUrl(), report.get('title'), format, params)
  },

  showReportOptions(record, opts = {}) {
    opts = Ext.applyIf(opts, { export: false })

    const windowId = String.format(
      'configure.report{0}',
      record ? String.format('[{0}]', record.get('id')) : ''
    )

    if (!WindowMgr.select(windowId)) {
      const panel = this.getReportOptionsPanel(record, opts)
      panel.addClass('report-options-panel')

      panel.addButton({
        text: t('Cancel'),
        handler() {
          const parentWindow = panel.findParentWindow()
          parentWindow?.close()
        }
      })

      panel.addButton(
        {
          text: t('OK'),
          cls: 'primary'
        },
        function () {
          const currentBaseTimestamp = this.getBaseTimestamp()
          const defaultValues = panel.getForm().getFieldValues()

          // If nothing selected in the date field, get the current usable timestamp
          const values = Ext.applyIf(defaultValues, {
            basetimestamp: currentBaseTimestamp
          })

          // If a Date object was selected, flatten it to its timestamp
          // For non-Date values, keep as is
          if (typeof values.basetimestamp.getTime === 'function') {
            values.basetimestamp = values.basetimestamp.getTime()
          }

          if (values.basetimestamp.length < 1) {
            // elide it
            delete values.basetimestamp
          }

          if (opts.callback) {
            opts.callback(values)
          } else if (opts.export) {
            this.openReportExport(values, record, record.get('options'))
          } else {
            this.open(record, {
              reportOptions: values,
              idBase: record.id
            })
          }

          const parentWindow = panel.findParentWindow()
          parentWindow && parentWindow.close()
        },
        this
      )

      const title = opts.export
        ? t('Export Report')
        : t('Configure {title} Report', { title: record.get('title') })

      const url =
        record.getSubscriptionStore().getUrl() + (opts.export ? '/export' : '/configure')

      WindowMgr.create({
        id: windowId,
        title,
        url,
        width: this.layout.newResource.width,
        autoHeight: this.layout.newResource.height === 'auto',
        height: this.layout.newResource.height,
        iconCls: 'new',
        shim: false,
        animCollapse: false,
        maximizable: false,
        resizable: false,
        items: panel,
        layout: 'fit',
        listeners: {
          show: {
            fn: panel.focus,
            scope: panel,
            delay: 300
          }
        }
      })

      if (opts.currentOptions) {
        panel.on(
          'afterlayout',
          function () {
            // since https://youtrack.smxemail.com/issue/SCL-3252
            let currentOptions

            if (
              Number.isInteger(opts.currentOptions && opts.currentOptions.basetimestamp)
            ) {
              const otherOpts = _.omit(opts.currentOptions, 'basetimestamp')

              currentOptions = Object.assign(
                {
                  basetimestamp: new Date(opts.currentOptions.basetimestamp)
                },
                otherOpts
              )
            } else {
              currentOptions = opts.currentOptions
            }

            this.getForm().setValues(currentOptions)
          },
          panel,
          { single: true }
        )
      }
    }
  },

  getFormatOptions(report) {
    const formats =
      (typeof report.get === 'function' && report.get('reportFormats')) ||
      report.reportFormats

    // intersection
    const availableFormats = ['PDF', 'HTML', 'CSV'].filter(
      (format) => formats?.indexOf(format) > -1
    )

    return availableFormats.map((format) => [format, TRANSLATIONS[format]])
  },

  getReportOptionsPanel(report, opts = {}, defaultsMap) {
    Ext.applyIf(opts, {
      subscription: false,
      export: false
    })

    const getOptionValue = function (option) {
      let defaultValue
      if (defaultsMap) {
        defaultValue = defaultsMap[option.name]
        return defaultValue || option.defaultValue
      } else if (option.name === 'locale') {
        return option.defaultValue || LOCALE || 'en'
      }
      return option.defaultValue
    }

    const items = []
    const options =
      (typeof report.get === 'function' ? report.get('options') : undefined) ||
      report.options
    if ((options != null ? options.length : undefined) > 0) {
      for (const option of Array.from(options)) {
        const optionConfig = this.createOptionConfig(
          option,
          opts.subscription,
          getOptionValue(option)
        )
        if (optionConfig) {
          items.push(optionConfig)
        }
      }
    }

    const isVisible = (items) =>
      items.some((item) => (item.xtype || item.getXType()) !== 'hidden')

    if (!opts.subscription && report.isTemporal()) {
      items.push(
        new DateField({
          fieldLabel: t('Base Date (Optional)'),
          name: 'basetimestamp',
          format: Date.patterns.SHORT,
          value: null
        })
      )
    }

    if (opts.export && report.isExportable()) {
      const formatOptions = this.getFormatOptions(report)
      items.push(
        new ComboBox({
          fieldLabel: t('Format'),
          value: formatOptions[0][0],
          hiddenName: 'format',
          store: this.getFormatOptions(report),
          editable: false,
          allowBlank: false,
          triggerAction: 'all'
        })
      )
    }

    if (opts.subscription) {
      const fs = new Ext.form.FieldSet({
        title: t('Options'),
        items,
        forceLayout: true
      })
      fs.setVisible(isVisible(items))
      return fs
    }
    const fp = new RecordFormPanel({
      items,
      forceLayout: true,
      visible: isVisible(items)
    })
    fp.setVisible(isVisible(items))
    return fp
  },

  createOptionConfig(option, subscription, optionValue) {
    if (!option.provided) {
      const atomicOptions = {
        fieldLabel: option.title,
        name: (subscription ? 'reportingoptions.' : '') + option.name,
        allowBlank: !option.required,
        value: optionValue
      }

      if (option.type === 'java.lang.Long' || option.type === 'java.lang.Integer') {
        return { xtype: 'numberfield', ...atomicOptions }
      }

      if (option.type === 'java.lang.String') {
        return { xtype: 'textfield', ...atomicOptions }
      }

      if (option.type === 'java.util.List') {
        if (option.repeating === true) {
          const storeData = option.values.map((v) => ({
            id: v.value,
            name: v.name
          }))

          const hiddenName = String.format(
            '{0}{1}',
            subscription ? 'reportingoptions.' : '',
            option.name
          )

          const combo = new MultiSelectComboBox({
            fieldLabel: option.title,
            name: hiddenName, // Set the name for form submission
            allowBlank: !option.required,
            store: new Ext.data.JsonStore({
              fields: ['id', 'name'],
              data: storeData
            }),
            displayField: 'name',
            valueField: 'id',
            width: 250
          })

          // Set initial selected values if any
          if (optionValue != null && optionValue !== '') {
            const selectedValues = optionValue.split(',').filter((val) => val !== '')
            combo.selectedValues = selectedValues
            combo.updateValue()
          }

          return combo
        }

        const store = []
        let displayValue, selectedValue

        for (const v of option.values) {
          const display = v.name
          const { value } = v
          store.push([value, display])

          if (value === optionValue) {
            displayValue = display
            selectedValue = value
          }
        }

        const hiddenName = String.format(
          '{0}{1}',
          subscription ? 'reportingoptions.' : '',
          option.name
        )

        const combo = new ComboBox({
          allowBlank: !option.required,
          editable: false,
          fieldLabel: option.title,
          hiddenName,
          hiddenValue: optionValue,
          local: true,
          store,
          triggerAction: 'all',
          value: displayValue
        })

        combo.on('render', function () {
          if (selectedValue != null) {
            combo.setValue(selectedValue)
          }
        })

        return combo
      }
    }

    if (subscription) {
      return {
        name: 'reportingoptions.' + option.name,
        value: optionValue,
        xtype: 'hidden'
      }
    }

    return null
  },

  getParentResources() {
    return [VendorResource]
  }
})

module.exports = Report
